@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Bad+Script&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: 'Roboto', sans-serif;
    }
}

